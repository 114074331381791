<template>
  <div class="shared-shortlist">
    <div class="shared-shortlist__container">
      <header class="shared-shortlist__header">
        <div class="shared-shortlist__header-heading">
          <div>
            <h1 class="shared-shortlist__header-title">
              {{ $t('pages.Shortlist.title') }}
            </h1>

            <div class="shortlist__header-session">
              <h2
                class="shared-shortlist__header-subtitle"
                data-test="position-title"
              >
                {{ shortlist.position.title }}
              </h2>
            </div>
          </div>
        </div>
      </header>

      <div class="shared-shortlist__content" v-if="shortlistItems.length">
        <transition-group
          name="slide-fade"
          tag="ul"
          class="shared-shortlist__items"
        >
          <li
            v-for="candidate in shortlistItems"
            :key="candidate.id"
            class="shared-shortlist__item"
          >
            <shared-shortlist-card
              :candidate="candidate"
              :shortlist="shortlist"
              data-test="shortlist-card"
            />
          </li>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import SharedShortlistCard from '@/pages/PublicAccess/components/SharedShortlistCard'

const sharedShortlistsHelpers = createNamespacedHelpers('shared_shortlists')
const currentUserHelpers = createNamespacedHelpers('current_user')

export default {
  name: 'SharedShortlist',

  components: {
    SharedShortlistCard
  },

  data () {
    return {
      shortlistItems: [],
      shortlist: {
        position: {
          title: ''
        }
      },
      isLoading: false
    }
  },

  // NOTE: if client is logged in, redirect to shortlist page
  async mounted () {
    try {
      await this.getCurrentUser()
      this.$router.push({ name: 'Shortlist', params: { id: this.shortlist.position.id } })
    } catch (error) {}
  },

  computed: {
    sharedShortlistToken () {
      return this.$route.params.sharedShortlistToken
    }
  },

  async created () {
    this.isLoading = true
    this.shortlist = await this.getSharedShortlist(this.sharedShortlistToken)
    this.shortlistItems = await this.getSharedShortlistCandidates(this.sharedShortlistToken)
    this.isLoading = false
  },

  methods: {
    ...sharedShortlistsHelpers.mapActions([
      'getSharedShortlist',
      'getSharedShortlistCandidates'
    ]),
    ...currentUserHelpers.mapActions([
      'getCurrentUser'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .shared-shortlist {
    --shortlist-card-min-width: 290px;

    background-color: $bg-red-light-03;
    height: 100vh;
    margin-top: 66px;
    overflow: auto;

    &__container {
      @extend %container;
    }

    &__header {
      margin-top: $base * 7;

      &-subtitle {
        font: $tx-title-2;
        margin-top: $base * 2;
      }
    }

    &__content {
      margin-top: $base * 6;
    }

    &__items {
      display: grid;
      gap: $base * 6;
      grid-template-columns:
        repeat(
          auto-fit,
          minmax(
            var(--shortlist-card-min-width),
            var(--shortlist-card-min-width)
          )
        );
    }
  }
</style>
