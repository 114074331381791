<template>
  <article class="shared-shortlist-card">
    <div class="shared-shortlist-card__container">
      <header class="shared-shortlist-card__header">
        <figure class="shared-shortlist-card__avatar">
          <img
            class="shared-shortlist-card__avatar-image"
            :src="candidate.photoUrl"
            :alt="$t('pages.SharedShortlistCard.avatarUrlAlt')"
            data-test="candidate-avatar"
          >
        </figure>

        <div>
          <h3
            class="shared-shortlist-card__name"
            data-test="candidate-name"
          >
            {{ candidate.name }}
          </h3>

          <h4
            class="shared-shortlist-card__career"
            data-test="career-name"
          >
            {{ candidate.careerName }}
          </h4>
        </div>
      </header>

      <a
        :href="candidate.linkedin"
        target="_blank"
        data-test="linkedin-link"
        class="shared-shortlist-card__linkedin"
      >
        <ev-icon
          name="linkedin"
        />
      </a>

      <div class="shared-shortlist-card__skills">
        <h4 class="shared-shortlist-card__section-title shared-shortlist-card__skills-title">
          {{ $t('pages.SharedShortlistCard.skillsTitle') }}
        </h4>

        <ul class="shared-shortlist-card__skills-list">
          <li
            v-for="skill in candidateSkills"
            :key="skill.id"
            class="shared-shortlist-card__skills-item"
          >
            <ev-badge
              :title="skill.skillLabel"
              variant="red-light"
              data-test="skill-badge"
            />
          </li>
        </ul>
      </div>

      <div class="shared-shortlist-card__description">
        <h4 class="shared-shortlist-card__section-title shared-shortlist-card__description-title">
          {{ $t('pages.SharedShortlistCard.descriptionTitle') }}
        </h4>

        <p
          class="shared-shortlist-card__description-text"
          data-test="description"
        >
          {{ candidate.applications && candidate.applications[0].comment }}
        </p>

        <ev-button
          class="shared-shortlist-card__description-button"
          variant="tertiary"
          size="small"
          data-test="see-more-button"
          target="_blank"
          no-padding
          @click="toggleResumeModal"
        >
          {{ $t('pages.SharedShortlistCard.seeMoreButton') }}
        </ev-button>
      </div>

      <div class="shared-shortlist-card__salary">
        <h4 class="shared-shortlist-card__section-title shared-shortlist-card__salary-title">
          {{ $t('pages.SharedShortlistCard.salaryTitle') }}
        </h4>

        <p
          class="shared-shortlist-card__salary-text"
          data-test="salary-pretension"
        >
          {{ $n(candidate.applications && candidate.applications[0].negotiatedSalary, 'currency') }}
        </p>
      </div>
    </div>

    <shared-shortlist-card-resume-modal
      v-show="showResumeModal"
      :candidate="candidate"
      @close="toggleResumeModal"
      data-test="resume-modal"
    />
  </article>
</template>

<script>
import EvBadge from '@revelotech/everestV2/EvBadge'
import EvButton from '@revelotech/everestV2/EvButton'
import EvIcon from '@revelotech/everestV2/EvIcon'
import SharedShortlistCardResumeModal from '@/pages/PublicAccess/components/SharedShortlistCardResumeModal'

export default {
  name: 'SharedShortlistCard',

  components: {
    EvBadge,
    EvButton,
    EvIcon,
    SharedShortlistCardResumeModal
  },

  props: {
    candidate: {
      type: Object,
      required: true
    },
    shortlist: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      showResumeModal: false
    }
  },

  computed: {
    candidateSkills () {
      return this.candidate.candidateSkills
        .filter((skill) => skill.isPrimary)
        .map((skill) => ({
          ...skill,
          skillLabel: skill.experienceTime
            ? `${skill.name} ${skill.experienceTime}A`
            : skill.name
        }))
    }
  },

  methods: {
    toggleResumeModal () {
      this.showResumeModal = !this.showResumeModal
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@revelotech/everestV2/tx_overline.scss";

.shared-shortlist-card {
  background-color: $white;
  border-radius: $b-radius;
  height: 100%;

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: $base * 6;
  }

  &__header {
    align-items: center;
    display: flex;
    flex-flow: nowrap;
    margin-bottom: $base * 4;
  }

  &__linkedin {
    margin-bottom: $base * 2;
  }

  &__avatar {
    margin-right: $base * 6;

    &-image {
      border-radius: 50%;
      height: 48px;
      width: 48px;
    }
  }

  &__name {
    font: $tx-title-3;
  }

  &__career {
    @extend %tx-overline;

    margin-top: $base;
  }

  &__section {
    &-title {
      @extend %tx-overline;

      margin-bottom: $base * 2;
    }
  }

  &__skills {
    &-list {
      display: flex;
      flex-flow: wrap;
      gap: $base * 2;
    }
  }

  &__description {
    margin-top: $base * 8;

    &-text {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      font: $tx-body-small;
      -webkit-line-clamp: 4;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-button {
      margin-top: $base;
    }
  }

  &__salary {
    margin-top: $base * 4;

    &-text {
      font: $tx-body-small;
    }
  }
}
</style>
